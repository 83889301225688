#dropdown-basic-button-infra {
  border: 0 !important;
  color: black !important;
  background-color: transparent !important;
  /* min-width: -moz-fit-content !important; */
  /* min-width: fit-content !important; */
  border-radius: 0 !important;
  box-shadow: 0 0px transparent !important;
  padding: 0px !important;
}

#dropdown-basic-button-infra:active {
  border: 0 !important;
  transform: translateY(0px);
}

.p-35 {
  padding: 0.35rem !important;
}

.shadow-on-bottom {
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.25);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f2d055 !important;
  /* color: white !important; */
}
