.title {

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 130%;
/* or 62px */


color: #1C115D;

}

.side {
    display: flex;
    width: 100%;
    height: 300px;
    background: #D9D9D9;
    justify-content: center;
    display: block !important;
}

.adjust-words {
    word-wrap:break-word;
}