.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #08111f;
}
.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  padding-top: 2rem;
  /* or 32px */

  color: #888888;
}
.side {
  display: flex;
  width: 100%;
  height: 300px;
  background: #d9d9d9;
  justify-content: center;
  display: block !important;
}

.border-css {
  border: 2px solid black !important;
  border-radius: 50px;
}

.adjust-words {
  word-wrap: break-word;
}

.blogs-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.blog-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
}

.blog-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.blog-image img {
  max-height: 150px;
  object-fit: cover;
}

.blog-title {
  font-weight: bold;
  color: #1c115d;
}

.blog-description {
  font-size: 0.9rem;
  color: #555;
}

.sidebar {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
}

.sidebar-item {
  border-bottom: 1px solid #eee;
}

.sidebar-title {
  font-weight: bold;
  color: #333;
}

.sidebar-content {
  color: #555;
}

.btn-primary {
  background-color: #1c115d;
  border: none;
}

.btn-primary:hover {
  background-color: #311a72;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blog-card {
    margin: 10px auto;
  }

  .sidebar {
    margin-top: 20px;
  }
}
.blog-details-container {
  padding: 20px;
  background-color: #f8f9fa;
}

.blog-title {
  /* font-size: 2rem; */
  font-weight: bold;
  color: #1c115d;
}

.blog-description,
.blog-author {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.blog-image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
  background: #fff;
}

.sidebar {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
}

.sidebar-item {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.sidebar-title {
  font-weight: bold;
  color: #333;
}

.loading {
  font-size: 1.2rem;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blog-title {
    font-size: 1.5rem;
  }

  .blog-description,
  .blog-author {
    font-size: 0.9rem;
  }

  .sidebar {
    margin-top: 20px;
  }
}
