.subscribe {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 2.5rem;
  gap: 2rem;
}

.subscribe-head {
  font-weight: bold;
  text-align: center;
}

.subscribe-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0;
  padding: 0.5rem 1rem;
  width: 50%;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.subscribe-content-mobile {
  display: block !important;
  width: 80% !important;
}
.subscribe-content .m-input + .title {
  padding: 0.5rem 5rem !important;
}

.subscribe-with-bg {
  height: 35rem;
  background-image: url("../../../public/assets/images/png/subscribe.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.subscribe-with-bg .subscribe {
  gap: 3rem;
}
.subscribe-with-bg .subscribe h1 {
  font-weight: 600;
}
.subscribe-with-bg .subscribe h3 {
  font-weight: lighter;
  text-align: center;
}
.subscribe-with-bg .subscribe .subscribe-content {
  background-color: white;
  margin-top: 2rem;
}

.w-90 {
  width: 90% !important;
}

.p-10 {
  padding: 10px !important;
}
